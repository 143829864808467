<template>
  <app-form-page v-bind="{ remote, model, rules, descriptors }" />
</template>

<script>
import item from './item';

export default {
  mixins: [item]
};
</script>
